import React from 'react';
import { Content } from '../content';
import { Title } from '../title';
import { Card } from '../card';

const TreasurerPrivacyPolicy = () => {
  return <div className="max-w-[1140px] m-auto w-full px-6 py-10 lg:px-2 xl:px-0">
    <Title className="sm:text-[30px] text-[20px] text-center">트레져러 개인정보 수집 및 이용 동의</Title>

    <Title className="pt-6 pb-2"> [필수] 개인정보 수집·이용 동의</Title>
    <Content>
      <Card className="flex flex-col justify-between text-black">
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>수집 ∙ 이용 목적</li></div>
          <div className="py-2 flex-1">이용자 식별 및 본인여부, 만 14세 미만 여부 확인</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>개인정보항목</li></div>
          <div className="py-2 flex-1">성명, 휴대전화번호, 이메일 주소, CI, 통신사정보, 생년월일, 성별, 내/외국인 정보, 회원관리 번호</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>보유기간</li></div>
          <div className="py-2 flex-1">트레져러 회원 탈퇴시 까지</div>
        </div>
      </Card>
      <Card className="flex flex-col justify-between text-black">
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>수집 ∙ 이용 목적</li></div>
          <div className="py-2 flex-1">서비스 관련 정보(공지사항 등)의 제공, 서비스 이용내역확인, 서비스 이용관련 불편사항 접수 및 처리, 서비스의 부정 이용방지</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>개인정보항목</li></div>
          <div className="py-2 flex-1">성명, 휴대전화번호, 이메일 주소, 통신사정보, 생년월일, 성별, 내/외국인정보, 회원관리 번호, 서비스 이용이력, 거래 내역</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>보유기간</li></div>
          <div className="py-2 flex-1">트레져러 회원 탈퇴시 까지</div>
        </div>
      </Card>
      <div className="mt-[20px]">귀하는 개인정보 수집∙이용에 동의를 거부할 수 있습니다. 다만, 동의를 거부할 경우, 회사가 제공하는 트레져러 금 구매 서비스를 이용할 수 없습니다.
      </div>
    </Content>
  </div>;
};

export { TreasurerPrivacyPolicy };