import React from 'react';
import { Title } from '../title';

const ServiceTerm = () => {
  return <div className="max-w-[1140px] m-auto w-full px-6 py-10 lg:px-2 xl:px-0">
    <Title className="sm:text-[30px] text-[20px] text-center">트레져러 서비스 이용 약관</Title>

    <Title>이용약관의 주요 내용 고지</Title><br />
    1. (주)트레져러(이하 “회사”라 합니다)가 제공하는 본 플랫폼은 만 14세 이상만 회원가입이 가능합니다. 이와 반대되는 사정은 회원이나 법정대리인에게 입증책임이 있습니다.<br />
    <br />
    2. 회사에서 운영하는 본 플랫폼은 [분할 소유권 거래, 상품 판매, e금상품권 판매를 지원하는 웹/모바일 플랫폼입니다.<br />
    <br />
    3. 회원이 회사의 플랫폼 이용을 위해 회사에 제공한 로그인 정보(회원의 아이디, 비밀번호 등)와 일치하는 정보가 회사의 [모바일/웹 페이 지]에 입력되어 회원계정으로의 접속이 이루어진 경우, 해당 접속 중
    이루어지는 모든 거래 행위는 회원의 진정한 의사에 기한 것으로 간 주됩니다. 따라서 회원께서는 각별히 [아이디, 비밀번호 기타 로그인 관련 및 거래관련 정보]에 대한 보안을 유지하여야 하고, 회원 자신의
    귀책으로 인한 개인정보 유출로 인해 발생할 수 있는 금융사고 또는 범죄로 인한 피해를 주의해야 합니다.<br />
    <br />
    4. 회원은 반드시 전기통신금융사기로 인하여 피해가 발생하지 않도록 스스로 주의를 기울여야 하고, 만일 전기통신금융사기로 인하여 수 사기관, 사법기관, 행정기관 기타 공권력의 조사가 이루어져 플랫폼의 해당
    [회원 계정] 의 동결이 필요할 경우, 회사는 회원의 동의 없이 [회 원 계정]을 동결할 수 있습니다.<br />
    <br />
    5. 회원의 불법행위로 인하여 회사에게 손해가 발생할 경우, 회사는 회원에게 법률상 손해배상을 청구할 수 있습니다. 그러므로 반드시 관 련 제반 법규를 준수하여 회사의 플랫폼을 이용하여 주시기
    바랍니다.<br />
    <br />
    6. 회사는 [전기통신사업법에 의한 기간통신사업자를 포함한 사업자], [블록체인 네트워크] 및 [회원]의 귀책사유로 인한 장애 등 회사와 무관하게 발생한 회원의 손해를 보상하거나 배상하지 않습니다.<br />
    <br />
    7. 회사는 안정적인 플랫폼 서비스 제공을 위하여 언제든지 [서버 또는 시스템] 점검을 할 수 있고, 회원은 해당 점검 종료 이후 서비스 이 용을 재개할 수 있습니다.<br />
    <br />
    <Title>제1조 목적</Title><br />
    이 약관은 주식회사 트레져러(이하 "회사"라 합니다)가 제공하는 본 플랫폼 이용과 관련하여 회사와 회원의 권리, 의무 및 책임 사항, 이용조건 및 절차와 기타 필요한 사항을 규정함을 목적으로
    합니다.<br />
    <br />
    <Title>제2조 정의</Title><br />
    이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br />
    <br />
    1. "플랫폼"이라 함은 정보통신망 접속이 가능한 단말기(PC, 휴대형 단말기 등의 각종 유무선 장치를 포함)를 통해서 분할 소유권 거래를 지원하는 웹 서비스를 의미합니다.<br />
    <br />
    2. "회원"이라 함은 플랫폼에 접속하여 이 약관에 따라 회사와 이용계약(이하 “이용계약”이라 합니다)을 체결하고, 회사가 제공하는 서비 스를 이용하는 고객을 말합니다.<br />
    <br />
    3. "아이디(ID 또는 계정)"라 함은 회원이 본 약관 및 개인정보처리방침에 동의한 후 회사가 회원을 식별하기 위해 회원에게 부여한 문자 또는 숫자의 조합을 말합니다.<br />
    <br />
    4. “분할 소유권”이라 함은 본 플랫폼에서 불특정 다수에게 판매되는 [상품]에 대한 일정 비율의 지분을 의미합니다.<br />
    <br />
    5. "e금상품권”이라 함은 불특정 다수에게 판매되는 실물 자산과 교환이 가능한 모바일상품권을 의미합니다.<br />
    <br />
    6. "콘텐츠"라 함은 회사가 회원에게 제공하는 부호, 문자, 도형, 색채, 음성, 음향, 이미지, 영상 등(이들의 복합체를 포함한다.) 온라인 디지털 형태의 정보나 자료를 말합니다.<br />
    <br />
    7. "결제업체"라 함은 신용카드, 휴대폰결제, 계좌결제 등 플랫폼에서 사용 가능한 전자지급수단을 제공하는 업체를 말합니다.<br />
    <br />
    8. "[조각 마켓]"란 회원이 본인의 의사에 따라 분할 소유권을 타인에게 판매하거나 타인으로부터 구매할 수 있는 플랫폼 내의 게시판 기능을 말하며, [조각 마켓]에서의 거래는 회사와 무관하게 전적으로 회원들
    간의 분할소유권의 양수도 의사에 따라 의해 이루어집니다.<br />
    <br />
    <Title>제3조 약관의 게시와 개정</Title><br />
    1. 회사는 이 약관의 내용을 회원이 쉽게 알 수 있도록 웹, 어플리케이션, 또는 이메일을 중 하나를 활용하여 게시합니다.<br />
    <br />
    2. 회사는 필요한 경우 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.<br />
    <br />
    3. 회사가 이 약관을 개정할 경우에는 개정 내용과 적용 일자를 명시하여 서비스에서 적용 일자 7일 전부터 적용 일자 전날까지 공지합니다. 다만, 회원에게 불리한 변경의 경우에는 적용 일자 30일 전부터
    공지합니다.<br />
    <br />
    4. 회사가 전항에 따라 공지하면서 회원에게 적용 일자 전날까지 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이
    개정약관에 동의한 것으로 봅니다.<br />
    <br />
    5. 회원은 개정 약관에 동의하지 않는 경우 적용 일자 전날까지 회사에 거부 의사를 표시하고 서비스 이용계약을 해지할 수 있습니다.<br />
    <br />
    <Title>제4조 약관의 해석</Title><br />
    1. 회사는 이 약관 외에 별도의 운영정책을 둘 수 있습니다.<br />
    <br />
    2. 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 운영정책, 이용안내, 관련 법령에 따릅니다.<br />
    <br />
    <Title>제5조 이용계약 체결</Title><br />
    1. 이용계약은 회원이 되고자 하는 자(이하 "가입 신청자"라 합니다)가 회사가 제공하는 약관의 내용에 대하여 동의를 한 다음 회사가 정한 절차에 따라 가입신청을 완료하고, 회사가 이러한 신청에 대하여
    승낙함으로써 체결됩니다.<br />
    <br />
    2. 회사는 가입 신청자의 신청에 대하여 아래 각호에 해당하는 사유가 있는 경우에는 승낙하지 않을 수 있으며, 가입 이후에도 아래 각호의 사유가 확인될 경우에는 승낙을 취소하거나 이용계약을 해지할 수
    있습니다.<br />
    <br />
    - 가. 가입 신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우<br />
    - 나. 실명이 아닌 명의 또는 타인의 명의를 이용한 경우<br />
    - 다. 허위 또는 잘못된 정보를 기재 또는 제공하거나, 회사가 제시하는 내용을 기재하지 않은 경우<br />
    - 라. 가입 신청자의 귀책 사유로 인하여 승인할 수 없거나 기타 이 약관에서 규정한 제반 사항을 위반하며 신청하는 경우<br />
    - 마. 부정한 용도 또는 별개의 영업 목적으로 서비스를 이용하고자 하는 경우<br />
    - 바. 관련 법령에 어긋나거나 사회의 안녕질서 혹은 미풍양속을 저해할 수 있는 목적으로 신청한 경우<br />
    - 사. 가입신청자가 서비스 이용을 제한하는 특정 국가의 외국인이거나 만 14세 미만일 경우<br />
    - 아. 이용계약의 성립 시기는 회사가 계정 생성 완료를 신청 절차상에서 표시하거나 제20조의 방식에 따른 통지가 가입 신청자에게 도달한 시점으로 합니다.<br />
    - 자. 회사는 회원이 제공한 정보가 사실과 일치하는지 여부를 확인하기 위하여 법령에 의하여 허용된 범위에서 전문기관을 통한 실명확인 또는 본인인증을 요청할 수 있습니다.<br />
    <br />
    <Title>제6조 회원 정보의 변경</Title><br />
    1. 회원은 플랫폼을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 플랫폼 관리를 위해 필요한 아이디는 수정할 수 없습니다.<br />
    <br />
    2. 회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 플랫폼에서 직접 수정하거나 고객센터를 통하여 회사에 변경 사항을 통지하여야 합니다.<br />
    <br />
    3. 회원이 전항의 변경사항을 회사에 통지하지 않아 발생한 불이익에 대하여 회사는 책임을 지지 않습니다.<br />
    <br />
    <Title>제7조 회원 정보의 관리</Title><br />
    1. 회사는 회원이 등록한 계정과 비밀번호 등이 회사에 등록된 것과 일치할 경우에는 별도의 확인절차 없이 이용자를 회원으로 간주합니다.<br />
    <br />
    2. 회원은 본인의 허가를 받지 않은 제삼자가 회원의 계정에 무단으로 접근하지 않도록, 비밀번호를 포함하여 계정 접근을 위해 필요한 일체의 정보를 안전하게 보관할 책임이 있습니다.<br />
    <br />
    3. 회원은 계정 접근정보를 분실했거나, 도용 당했거나, 제3자에게 공개되었음을 인지한 경우 이를 즉시 회사에 통지해야 합니다. 회사는 즉시 계정 이용중단 등의 조치를 할 수 있습니다.<br />
    <br />
    4. 회원의 계정 비밀번호 등 접근정보가 분실, 도용 혹은 공개되어 회원에게 발생한 손해에 대하여 회사는 책임을 부담하지 아니합니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지
    아니합니다.<br />
    <br />
    <Title>제8조 개인정보의 보호</Title><br />
    회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보 보호법 등 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 이용에 대해서는 관련 법령 및 회사의
    개인정보처리방침이 적용됩니다. 다만, 회사가 제작하여 제공한 화면 이외의 외부로 링크된 화면 등에서는 회사의 개인정보처리방침이 적용되지 않습니다.<br />
    <br />
    <Title>제9조 회사의 의무</Title><br />
    1. 회사는 관련 법령과 이 약관을 준수하며, 계속적이고 안정적으로 플랫폼 서비스를 제공하기 위하여 최선을 다하여 노력합니다.<br />
    <br />
    2. 회사는 회원이 안전하게 플랫폼을 사용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖출 수 있으며, 개인정보처리방침을 공시하고 준수합니다.<br />
    <br />
    3. 회사는 플랫폼 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 하며, 플랫폼 내 게시판, 전자우편 등을 통하여 회원에게 처리 과정 및 결과를 전달할 수
    있습니다.<br />
    <br />
    <Title>제10조 회원의 의무</Title><br />
    1. 회원은 아래의 행위를 하여서는 안 됩니다.<br />
    <br />
    - 가. 타인의 정보도용<br />
    - 나. 회사가 게시한 정보의 변경 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시<br />
    - 다. 회사와 기타 제삼자의 저작권 등 지적 재산권에 대한 침해<br />
    - 라. 회사 및 기타 제삼자의 명예를 손상하거나 업무를 방해하는 행위<br />
    - 마. 외설 또는 폭력적인 메시지, 화상, 음성, 허위사실, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위<br />
    - 바. 회사의 사전 동의 없이 영리를 목적으로 서비스를 사용하는 행위<br />
    - 사. 부정한 방법을 통하여 서비스에 접속하는 행위, 노출횟수 및 클릭횟수를 부정하게 생성하거나 증가시키는 행위, 서비스 이용 신청을 하는 행위, 회사의 서버에 부하를 일으키는 행위<br />
    - 아. 다른 회원의 개인정보 및 계정정보를 수집하는 행위<br />
    - 자. 기타 불법적이거나 부당한 행위<br />
    <br />
    2. 회원은 관계 법령, 이 약관, 이용안내 및 플랫폼과 관련하여 회사가 공지하거나 통지한 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.<br />
    <br />
    <Title>제11조 권리의 귀속</Title><br />
    1. 플랫폼에 대한 저작권 및 지적 재산권은 회사에 귀속됩니다.<br />
    <br />
    2. 회사는 플랫폼과 관련하여 회원에게 회사가 정한 이용조건에 따라 플랫폼을 이용할 수 있는 이용권 만을 부여하며, 회원은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.<br />
    <br />
    <Title>제12조 플랫폼 서비스의 제공 등</Title><br />
    1. 회사는 플랫폼 서비스를 연중무휴, 1일 24시간 제공합니다. 다만 회사는 플랫폼 서비스를 일정 범위로 나누어 범위 별로 이용가능 시간을 별도로 지정할 수 있으며, 그 내용을 사전에 공지합니다.<br />
    <br />
    2. 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 합리적인 이유가 있는 경우 플랫폼 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 회사는 사전에 초기 화면이나
    공지사항 게시판을 통하여 회원에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.<br />
    <br />
    3. 회사는 필요한 경우 점검을 할 수 있으며, 점검 시간은 서비스 제공 화면에 공지한 바에 따릅니다.<br />
    <br />
    4. 2024년 10월 11일 이후에 발생(적립)한 랜덤 적립 리워드(실적에 상관없이 무작위로 지급된 포인트나 조각)은 1년간 유효하며 유효기간 내에게 사용되지 않은 리워드는 소멸됩니다. 또한, 랜덤
    적립리워드가 아닌 실적으로 발생한 리워드(구매 이벤트 등)는 2년간 유효하며 유효기간 내에 사용되지 않은 리워드는 소멸됩니다. 다만, 2024년 10월 11일 이전에 발생(적립)한 리워드에 대하여는 본 조항이
    적용되지 않으며, 상법 제64조에 따라 5년이 경과하면 소멸합니다.<br />
    <br />
    <Title>제13조 플랫폼의 변경</Title><br />
    1. 회사는 안정적인 플랫폼 서비스 제공을 위하여 플랫폼 서비스의 내용, 운영상, 기술상 사항 등을 변경할 수 있습니다.<br />
    <br />
    2. 회사는 플랫폼 서비스를 변경할 경우 변경내용과 적용 일자를 명시하여 사전에 공지합니다. 다만, 회사가 사전에 공지할 수 없는 부득이한 사유가 있는 경우 사후에 공지할 수 있습니다.<br />
    <br />
    3. 회원은 플랫폼 서비스 변경에 동의하지 않을 경우 회사에 거부 의사를 표시하고 이용계약을 해지할 수 있습니다.<br />
    <br />
    <Title>제14조 플랫폼의 이용</Title><br />
    회사는 회원에게 다음 서비스를 제공합니다. 해당 서비스를 받기 위해서는 플랫폼 내의 별도 약관에 동의해야 합니다.<br />
    <br />
    1. 상품의 등록 및 분할소유권 매매<br />
    2. e금상품권의 판매<br />
    <br />
    <Title>제15조 이용제한 등</Title><br />
    1. 회사는 다음 각호에 해당하면 회원의 서비스 로그인을 제한할 수 있습니다.<br />
    <br />
    - 가. 비밀번호 연속 오류의 경우<br />
    - 나. 해킹 및 사기 사고가 발생한 경우<br />
    - 다. 명의도용으로 의심되는 경우<br />
    - 라. 국가기관이 관련 법령에 따라 서비스 제한을 요청하는 경우<br />
    - 마. 시세조종, 자금세탁, 불공정거래, 범죄행위 등에 관여하고 있거나 관여하고 있다고 합리적으로 의심되는 경우<br />
    - 바. 회원으로 가입된 이용자가 만 14세 미만<br />
    - 사. 회원이 타인 명의의 계좌로 원화 입출금 거래를 하는 것으로 확인된 경우<br />
    - 아. 기타 각호에 준하는 사유가 발생하거나 그러한 사유 발생의 방지를 위한 조처가 필요한 경우<br />
    <br />
    2. 보이스피싱, 자금세탁 등 범죄에 악용될 가능성이 있는 계정(소액, 장기 미사용 계정 등)은 운영정책에 따라 보안등급이 조정되거나 정지될 수 있습니다.<br />
    <br />
    3. 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 운영정책, 이용안내 등에서 회사가 정하는 바에 의합니다.<br />
    <br />
    4. 본 조에 따라 서비스 이용을 제한하거나 이용계약을 해지하는 경우에는 회사는 제20조에 따른 방법으로 회원에게 통지합니다.<br />
    <br />
    5. 회원은 본 조에 따른 이용제한 등에 대해 고객센터를 통하여 이의신청을 할 수 있습니다. 이때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.<br />
    <br />
    <Title>제16조 이용계약 해지</Title><br />
    1. 회원은 언제든지 플랫폼 내 정보 관리 또는 설정 메뉴 또는 고객센터 등을 통하여 이용계약 해지를 신청할 수 있으며, 회사는 관련 법령이 정하는 바에 따라 이를 즉시 처리하여야 합니다.<br />
    <br />
    2. 회사는 회원에게 다음과 같은 사유가 발생할 경우 시간을 정하여 플랫폼 이용을 제한함과 동시에 시정요구를 할 수 있습니다. 시정요구에도 불구하고 상당한 기간 내에 시정되지 않거나 2회 이상 반복적으로 같은
    위반행위를 하는 경우에는 이용계약을 해지할 수 있습니다.<br />
    - 가. 본 약관 제10조에 규정된 회원의 의무를 위반하거나 제18조에 규정된 이용제한 사유에 해당하는 경우<br />
    - 나. 저작권법을 위반한 불법프로그램의 제공 및 운영 방해, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반한 불법 통신 및 해킹, 악성 프로그램의 배포, 접속 권한 초과행위 등과 같이 관련 법령을
    위반한 경우<br />
    - 다. 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우<br />
    - 라. 그 밖에 각호에 준하는 사유로서 이용계약을 유지할 수 없는 사유가 발생하는 경우<br />
    <br />
    3. 전 항에 따른 이용계약 해지 시 플랫폼 이용을 통해 획득한 모든 혜택이 소멸하며, 회사는 이에 대해 별도로 보상하지 않습니다.<br />
    <br />
    4. 본 조에 따라 서비스 이용계약을 해지하는 경우에는 회사는 제20조에 따른 방법으로 회원에게 통지합니다.<br />
    <br />
    5. 이용계약 해지가 완료되는 경우 관련 법령 및 개인정보처리방침에 따라 회사가 보유하여야 하는 정보를 제외한 회원의 모든 정보가 삭제됩니다.<br />
    <br />
    6. 전 항에도 불구하고 제2항에 따라 회사가 이용계약을 해지하는 경우 회사는 회원의 이의신청 접수 및 처리 등을 위하여 일정 기간 회원의 정보를 보관할 수 있으며, 해당 기간이 지난 후에 회원의 정보(단,
    분할 소유권 거래 내역은 제외)를 삭제합니다.<br />
    <br />
    <Title>제17조 회원에 대한 통지</Title><br />
    1. 회사가 회원에 대하여 통지를 하는 경우 이 약관에 별도의 규정이 없는 한 회원이 제공한 전자우편주소, 모바일 문자, 전화, 우편, 메신저 등으로 할 수 있습니다.<br />
    <br />
    2. 회사는 회원 전체에 대하여 통지를 하는 경우, 이를 7일 이상 플랫폼 내 게시판에 게시함으로써 전항의 통지에 갈음할 수 있습니다.<br />
    <br />
    <Title>제18조 책임 제한</Title><br />
    1. 회사 또는 회원은 본 약관을 위반하여 상대방에게 손해를 입힌 경우에는 그 손해를 배상할 책임이 있습니다. 다만, 행위자의 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.<br />
    <br />
    2. 다음과 같은 사유로 회원에게 손해가 발생하였을 경우, 회사가 회원의 손해 발생을 방지하기 위하여 필요한 관리자의 주의를 다하였음을 입증한 때에는 그 손해에 대하여 책임을 지지 아니합니다.<br />
    - 가. 전시, 사변, 천재지변, 또는 이에 준하는 국가 비상사태 등 어쩔 수 없는 경우<br />
    - 나. 정부 기관의 사실상 또는 법률상 행정처분 및 명령 등에 대한 준수로 인한 경우<br />
    - 다. 전기통신사업법에 의한 기간통신사업자를 포함한 통신서비스 업체의 서비스 장애로 인한 경우<br />
    - 라. 회사가 관리할 수 없는 외주 시스템의 하자 또는 이용자 측 귀책 사유로 인하여 서비스에 장애가 발생한 경우<br />
    - 마. 순간적인 서비스 접속 증가, 일부 분할 소유권의 주문 폭주 등으로 인한 서버의 장애가 발생한 경우<br />
    - 바. 플랫폼 내 거래시스템, 각 분할 소유권 통지를 위한 블록체인 네트워크 장애 또는 오류가 발생한 경우<br />
    <br />
    <Title>제19조 준거법 및 재판관할</Title><br />
    1. 회사와 회원간 발생한 분쟁에 대하여는 대한민국 법을 준거법으로 합니다.<br />
    <br />
    2. 회사와 회원간 발생한 분쟁에 관한 소송의 관할법원은 서울중앙지방법원으로 합니다.<br />
    <br />
    <br />
    <Title>부칙</Title><br />
    (시행일) 이 이용약관은 2024년 10월 11일부터 적용됩니다.<br />
  </div>;
};

export { ServiceTerm };