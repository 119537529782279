import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './Main';
import { TermConditions } from './term-conditions';
import { PrivacyPolicy } from './privacy-policy';
import { PrivacyPolicyFaceOff } from './privacy-policy-face-off';
import ContactUs from './ContactUs';
import { Terms } from './components/treasurer/terms';
import { MarketingAgree } from './components/treasurer/marketing-agree';
import { GroupPurchaseTerm } from './components/treasurer/group-purchase-term';
import { ServiceTerm } from './components/treasurer/service-term';
import { TreasurerPrivacyPolicy } from './components/treasurer/privacy-policy';
import { TreasurerThirdPartyPrivacyPolicy } from './components/treasurer/third-party-privacy-policy';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/term-conditions" element={<TermConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/privacy-policy-face-off" element={<PrivacyPolicyFaceOff />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/treasurer/terms" element={<Terms />} />
        <Route path="/treasurer/marketing-agree" element={<MarketingAgree />} />
        <Route path="/treasurer/group-purchase-term" element={<GroupPurchaseTerm />} />
        <Route path="/treasurer/service-term" element={<ServiceTerm />} />
        <Route path="/treasurer/privacy-policy" element={<TreasurerPrivacyPolicy />} />
        <Route path="/treasurer/third-party-privacy-policy" element={<TreasurerThirdPartyPrivacyPolicy />} />
      </Routes>
    </Router>
  );
};

export default App;
