import React, { useState } from 'react';
import { Footer } from './components/footer';

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e:any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div>
      <div className="w-full bg-[rgba(0,0,0,0.92)] h-[76px] fixed flex justify-center items-center">
        <div className="w-full flex justify-between items-center max-w-[1140px] px-10">
          <div>
            <img src="/images/logo.svg" alt="logo" className="w-[156px]" />
          </div>

          <div className="lg:flex hidden text-white justify-center items-center text-[18px] font-p-bold gap-10">
            <div className="cursor-pointer">Company</div>
            <div className="cursor-pointer">MyB</div>
            <div className="cursor-pointer">Partners</div>
          </div>

        </div>
      </div>
      <form className="p-6 pt-32 max-w-6xl mx-auto" onSubmit={(e) => {
        alert('접수되었습니다.');
        e.preventDefault();
      }}>
        <div className="flex flex-col gap-6 min-h-[55vh]">
          <div className="col-span-1 sm:col-span-2 text-center sm:text-left">
            <h2 className="text-2xl sm:text-4xl font-p-bold text-black">Contact Us</h2>
          </div>

          <div className="flex flex-col sm:flex-row gap-6">
            <div className="flex-1">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                maxLength={60}
                className="mt-1 block w-full rounded-md border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm py-4 pl-2"
              />
            </div>

            <div className="flex-1">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                maxLength={60}
                className="mt-1 block w-full rounded-md border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm py-4 pl-2"
              />
            </div>
          </div>

          <div>
            <label htmlFor="message" className="block text-sm font-medium text-gray-700">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
              maxLength={60}
              rows={4}
              className="mt-1 block w-full rounded-md border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm py-4 pl-2 resize-none"
            ></textarea>
          </div>

        </div>
        <div className="col-span-2 flex justify-end pb-6">
          <button
            type="submit"
            className="h-[54px] w-[120px] px-[16px] py-[6px] text-white bg-[rgb(30_93_217)] rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            SUBMIT
          </button>
        </div>
      </form>

      <Footer />
    </div>
  );
}

export default ContactUs;
