import React from 'react';
import { Title } from '../title';

const Terms = () => {
  return <div className="max-w-[1140px] m-auto w-full px-6 py-10 lg:px-2 xl:px-0">
    <Title className="sm:text-[30px] text-[20px] text-center">트레져러 금 서비스 이용 약관</Title>

    <Title>제1조 [목적]</Title>
    이 약관은 (주)트레져러(이하 ‘회사’라 함)이 실물 자산과 교환이 가능한 모바일상품권(이하 ‘상품권’이라 함)의 구매 및 사용에 대한 편의를 제공하기 위하여 운영하는 트레져러 앱서비스(이하 ‘트레져러’라 함)의 이용에 대해 회사와 회원의 권리, 의무 및 책임 사항, 기타 필요한 사항을 규정함을 목적으로 합니다.<br />

    <br />
    <Title>제2조 [용어의 정의]</Title>
    이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br />
    <br />
    1. “상품권”이라 함은 실물 자산과 교환이 가능한 모바일상품권으로 교환 대상물은 아래와 같습니다. 다만, 교환 대상물의 현물 인출 시 제품의 형태는 트레져러의 운영정책에 따릅니다.<br />
    - e금: 실물 금(Gold, 순도 99.99%)<br />
    - e은: 실물 은(Silver, 순도 99.99%)<br />
    - e플래티넘: 실물 백금(PlaOnum, 순도 99.95%)<br />
    - e팔라듐: 실물 팔라듐(Palladium, 순도 99.95%)<br />
    <br />
    2. “트레져러”라 함은 회원이 상품권의 실물교환, 매수, 매도, 선물 등을 간편하게 할 수 있는 앱서비스를 말합니다.<br />
    <br />
    3. “트레져러자산”이라 함은 트레져러에서 적립, 충전, 매수, 매도 등을 할 수 있는 모든 디지털자산을 의미합니다.<br />
    <br />
    4. “KRW”는 트레져러에서 사용되는 가상의 포인트로서 회원이 회사의 계좌로 원화를 입금하면 입금된 금액만큼 적립되며, 원화 현금으로 반환이 가능한 교환가치 수단으로 상품권 매수 대금의 결제, 수수료 납부
    등에 활용할 수 있습니다.<br />
    <br />
    5. “포인트”는 트레져러에서 사용되는 가상의 포인트로서 회사가 정한 기준에 따라 회원에게 제공하며, 상품권 매수 대금의 결제, 수수료 납부 등에 활용할 수 있습니다.<br />
    <br />
    6. “서비스”라 함은 트레져러에서 회원이 상품권을 매수, 매도, 실물교환, 환매를 요청할 수 있는 서비스를 말합니다.<br />
    <br />
    7. “홈페이지”라 함은 회사의 웹사이트(www.treasurer.co.kr)를 말합니다.<br />
    <br />
    8. “전자지갑”이라 함은 트레져러에서 회원이 보유하는 자산을 전자적으로 표시한 소프트웨어적 장치를 말합니다. 전자지 갑에는 회원이 보유한 자산이 표시됩니다.<br />
    <br />
    9. “매수”라 함은 회원이 상품권을 구입하는 행위를 말하며, 상품권 구입을 신청한 회원의 전자지갑으로 상품권이 전송등록 되었을 때 매수가 완료됩니다.<br />
    <br />
    10. “매도”라 함은 회원이 상품권을 판매하는 행위를 말하며, 판매를 신청한 회원의 지갑에서 상품권의 구입을 신청한 회사의 전자지갑으로 전송·등록되었을 때 매도가 완료됩니다.<br />
    <br />
    11. “실물교환”이라 함은 상품권을 소지한 회원이 실물 금, 실물 은으로 교환을 청구하는 것을 말합니다. 실물교환은 발행사와 업무대행 계약을 체결한 회사가 제공할 수 있습니다.<br />
    <br />
    12. “회원”이라 함은 트레져러 회원가입 절차를 통해 상품권이용약관, 서비스이용약관, 개인정보처리방침에 동의하고, 개인정보를 회사에 제공하여 실명인증, 실명계좌인증, 아이디(ID) 및 패스워드를 생성한 국내
    주소지를 두고 있는 내국인, 외국인, 법인을 말합니다.<br />
    <br />
    13. “회원등급”이라 함은 회사가 지정하는 기준에 따라 적용되는 회원별 등급으로서 회사는 회원의 회원등급에 따라 혜택을 차등 적용할 수 있습니다.<br />
    <br />
    14. “충전”이라 함은 회원이 상품권 구입을 목적으로 본인의 금융계좌에서 회사의 서비스계좌로 현금을 입금하고, 회사는 회원의 전자지갑에 회원이 입금한 원화와 동일하게 KRW를 표시하는 것을 말합니다. 또한
    포인트 자산도 전자지갑에 원화 와 동일하게 충전할 수 있습니다.<br />
    <br />
    15. “서비스계좌”라 함은 회원이 충전을 위해 회사로 원화를 입금할 수 있는 회사의 법인 금융계좌를 말합니다.<br />
    <br />
    16. “운용계좌”라 함은 트레져러를 통해 상품권을 매수, 매도, 실물 교환, 환매, 선물하기 등을 함으로써 발생하는 수수료를 수취하기 위한 회사의 법인 금융계좌를 말합니다.<br />
    <br />
    17. “운영정책”이라 함은 트레져러에서 회원에게 원활한 서비스 제공을 위해 회원에게 공지한 수수료, 거래한도, 입출금한도, 선물한도, 시세정보, 거래시간, 이벤트, 거래시간, 실물교환 등의 정책을 말합니다.<br />
    <br />
    18. “발행풀(issuance pool)”이라 함은 실물 자산과 교환이 가능한 모바일상품권을 발행하는 사업자풀을 말합니다.<br />
    <br />
    19. “시세”라 함은 트레져러에서 거래되는 자산의 현재가격을 말합니다.<br />
    <br />
    20. “종가”라 함은 거래당일 24시에 형성되는 최종가격을 말합니다.<br />
    <br />
    21. “거래시간”이라 함은 트레져러 앱서비스를 활용해 상품권을 매수, 매도할 수 있는 시간을 말합니다.<br />
    <br />
    22. “매매거래”라 함은 매수, 매도 거래 모두를 말합니다.<br />
    <br />
    <Title>제3조 [적용의 범위 및 약관의 효력 등]</Title><br />
    ⓵ 본 약관은 트레져러를 이용하고자 하는 모든 회원에게 적용됩니다.<br />
    <br />
    ⓶ 본 약관의 내용은 트레져러 또는 홈페이지에 게시하거나 기타의 방법으로 회원에게 공지하고, 이에 동의한 회원이 서비스를 이용함으로써 효력이 발생합니다.<br />
    <br />
    ⓷ 회사는 필요하다고 인정되는 경우 본 약관을 개정할 수 있으며, 약관을 개정할 경우 그 적용일자 및 내용, 개정사유 등을 명시하여 현행 약관과 함께 적용일자 10일 전부터 제2항과 같은 방법으로 공지합니다.
    단, 회원의 권리 또는 의무에 관한 중요한 규정을 변경하거나 회원에게 불리하게 약관의 내용을 변경하는 경우에는 최소한 그 적용일자 30일 이전부터 공지합니다.<br />
    <br />
    ⓸ 본 약관의 적용기간은 회원의 가입일로부터 해지일까지입니다. 다만 채권 또는 채무관계가 있을 경우에는 채권·채무의 이행이 완료될 때까지는 그에 필요한 한도 내에서 해지일 이후에도 이 약관이
    적용됩니다.<br />
    <br />
    ⓹ 회원은 변경된 약관에 동의하지 않을 권리가 있으며, 변경된 약관에 동의하지 않을 경우에는 트레져러 이용을 중단하고 탈퇴할 수 있습니다. 다만, 회원이 변경된 약관의 적용일까지 별도의 의사를 표시하지 않은
    경우에는 변경된 약관에 동의한 것으로 간주한다는 뜻을 제3항의 방법으로 고지하였음에도 불구하고 회원이 변경된 약관의 적용일까지 별도의 의사표시를 하지 않은 경우에는 변경된 약관에 동의한 것으로
    간주합니다.<br />
    <br />
    <Title>제4조 [약관의 해석 및 그 외 준칙]</Title><br />
    ⓵ 회사는 이 약관 외에 별도의 운영정책을 둘 수 있으며, 운영정책은 트레져러를 통해 공지하며 서비스 제공을 위한 세부적인 사항을 규정합니다.<br />
    ⓶ 본 약관에 명시되지 않은 사항에 대해서는 전기통신기본법, 전기통신사업법, 전자상거래 등에서의 소비자보호에 관한 법률, 저작권법, 독점 규제 및 공정거래에 관한 법률 등 기타 관계법령 및 회사가 정한
    운영정책 등의 규정에 의합니다.<br />
    <br />
    <Title>제5조 [서비스 종류]</Title><br />
    ⓵ 회사에서 제공하는 서비스에는 상품권의 실물 교환, 매수, 선물하기, 송금하기, 충전하기, 시세정보검색 관련 서비스, 거래내역조회 등이 있습니다.<br />
    <br />
    ⓶ 회사는 발행사와 개별 계약을 통해 발행사가 발행한 상품권을 공급받아 회원에게 판매하며, 회원의 요청이 있을 시 실물 금, 실물 은으로 교환하는 등의 서비스를 제공합니다.<br />
    <br />
    ⓷ 회사가 제공하는 서비스의 종류는 회사의 사정에 의하여 변경될 수 있으며, 트레져러와 관련한 저작권 및 지식재산권은 회사에 귀속됩니다.<br />
    <br />
    ⓸ 회원은 트레져러에서 제공하는 서비스를 활용한 유사 서비스 제공 및 상업적 활동을 할 수 없습니다. 회원이 이러한 행위를 함으로 인해 발생하는 모든 피해에 대한 민, 형사상 책임은 해당 회원에게
    있습니다.<br />
    <br />
    <Title>제6조 [서비스 이용시간]</Title><br />
    ⓵ 서비스 이용 시간은 서비스 운영정책에 따르며, 운영정책은 트레져러 앱서비스 또는 홈페이지에 공지합니다.<br />
    <br />
    ⓶ 서비스는 다음의 경우에는 서비스 이용 시간을 긴급하게 변경하거나 중지할 수 있습니다. 이러한 경우 회사는 사전에 이를 공지하며, 부득이한 경우 사후 공지할 수 있습니다.<br />
    - 전산시스템의 장애 발생으로 정상적인 매매거래를 할 수 없는 경우<br />
    - 그 밖에 회사가 서비스 관리상 필요하다고 인정하는 경우<br />
    <br />
    ⓷ 회사는 서비스를 일정 범위로 분할하여 각 범위 별로 이용 가능한 시간을 별도로 정할 수 있으며 이 경우 그 내용을 공지합니다. 단 회사가 사전에 통지할 수 없는 부득이한 경우 사후 공지할 수
    있습니다.<br />
    <br />
    <Title>제7조 [서비스 중지 및 재개]</Title><br />
    ⓵ 서비스는 다음 각 호에 해당하는 경우 거래 자산별 또는 전체 거래 자산에 대해 서비스를 일시정지 또는 종결할 수 있습니다.<br />
    1. 천재·지변, 전시·사변, 경제사정의 급격한 변동 또는 급격한 변동이 예상되는 경우 그 밖에 시장관리상 필요하다고 인정하는 경우<br />
    2. 시스템의 장애 발생으로 정상적인 매매거래를 할 수 없는 경우<br />
    3. 그 밖에 투자자보호 및 시장관리를 위해 거래소가 필요하다고 인정할 경우<br />
    <br />
    ⓶ 서비스는 거래자산의 시세가 직전 종가에 대비하여 급등락할 경우 매매거래를 중단한 후 재개하거나 종결할 수 있습니다. 다만, 서비스 중단 또는 재개에 따른 세부사항은 운영정책에서 제공합니다.<br />
    <br />
    <Title>제8조 [실물인출 전환 및 서비스 중단]</Title><br />
    서비스는 거래자산별 직전 종가를 기준으로 전체 고객 보유자산의 10% 이상 매도가 발생한 경우 실물인출로 전환 또는 종결할 수 있습니다.<br />
    <br />
    <Title>제9조 [전산장애시의 서비스 이용방법]</Title><br />
    ① 시스템 장애로 인해 매매거래가 중단된 경우 또는 매매거래의 진행이 곤란하다고 인정되는 경우에는 응급수단을 활용해 매도거래만을 접수 받을 수 있다. 다만, 매도거래 접수를 위한 응급수단은 회원에게 별도
    공지합니다.<br />
    <br />
    ② 상기 1호와 같이 응급수단으로 매도거래를 접수 받을 경우, 트레져러 홈페이지 또는 앱서비스를 통해 공지된 시세를 기준으로 매도거래가 이루어집니다. 다만, 시세의 획득과 제공이 원활하지 않은 경우에는
    매도거래를 실시하지 않을 수 있습니다.<br />
    <br />
    <Title>제10조 [서비스 수수료]</Title><br />
    ① 회원은 상품권의 실물 교환, 매수, 매도, 선물하기, 송금하기 등의 서비스를 이용하는 경우 이에 따른 수수료를 지급합니다.<br />
    <br />
    ② 서비스 수수료는 서비스 운영정책에 따르며, 운영정책은 트레져러 앱서비스 또는 홈페이지에 공지합니다.<br />
    <br />
    <Title>제11조 [서비스 이용제한 등]</Title><br />
    ① 회사는 다음 각 호에 해당할 경우, 회원의 서비스 이용을 제한할 수 있습니다.<br />
    1. 비밀번호 연속 오류의 경우<br />
    2. 해킹 및 사기 사고가 발생한 경우<br />
    3. 명의도용으로 의심되는 경우<br />
    4. 국가기관이 관련 법령에 따라 서비스 제한을 요청하는 경우<br />
    5. 시세조종, 자금세탁, 불공정거래, 범죄행위 등에 관여하고 있거나 관여하고 있다고 합리적으로 의심되는 경우<br />
    6. 회원으로 가입된 이용자가 만 14세 미만이거나 또는 비거주 외국인으로 확인된 경우<br />
    7. 회원이 타인 명의의 계좌로 원화 입출금 거래를 하는 것으로 확인된 경우<br />
    8. 기타 위 각호에 준하는 사유가 발생하거나 그러한 사유 발생의 방지를 위한 조치가 필요한 경우<br />
    <br />
    ② 보이스피싱, 자금세탁 등 범죄에 악용될 가능성이 있는 계정(소액, 장기 미사용 계정 등)은 운영정책에 따라 보안등급이 조정될 수 있습니다.<br />
    <br />
    ③ 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 운영정책, 이용안내 등에서 회사가 정하는 바에 의합니다.<br />
    <br />
    ④ 본 조에 따라 서비스 이용을 제한하거나 이용계약을 해지하는 경우에는 회사는 제13조의 방법에 따라 회원에게 통지합니다.<br />
    <br />
    ⑤ 회원은 본 조에 따른 이용제한 등에 대해 회사에 이의신청을 할 수 있습니다. 이때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.<br />
    <br />
    <Title>제12조 [서비스 변경]</Title><br />
    ① 회사는 변경될 서비스의 내용 및 제공일자를 제13조(회원에 대한 통지)의 방법에 따라 회원에게 통지하고 서비스를 변경하여 제공할 수 있습니다.<br />
    <br />
    ② 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.<br />
    1. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우<br />
    2. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우<br />
    3. 사용처와의 계약 종료 등과 같은 회사의 제반 사정으로 서비스를 유지할 수 없는 경우<br />
    4. 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우<br />
    5. 기타 서비스를 유지할 수 없는 중대한 사유가 발생한 경우<br />
    <br />
    ③ 본 조 제2항에 의한 서비스 중단의 경우 회사는 게시판이나 전자우편 등의 방법으로 중단 사실을 회원에게 사전에 통지합니다. 이 때, 전자우편 수신을 거부한 회원의 경우나 회사가 통제할 수 없는 사유로 인한
    서비스의 중단(회사의 고의, 과실이 없는 디스크 장애, 시스템다운 등)으로 인하여 사전 통지가 불가능한 경우에는 회사가 책임을 지지 않습니다.<br />
    <br />
    ④ 본 조 제2항의 서비스 중단으로 인하여 발생한 회원의 손해에 대하여 회사는 고의 또는 중대한 과실이 없는 한 책임을 부담하지 않습니다.<br />
    <br />
    <Title>제13조 [서비스 이용에 대한 이용자의 의무와 책임]</Title><br />
    회사가 정상적으로 서비스를 제공한 경우 이로 인하여 발생하는 개인 또는 기업 간의 분쟁에 관한 모든 민·형사상 책임은 이용자에게 있습니다.<br />
    <br />
    <Title>제14조 [서비스 종료]</Title><br />
    회사가 서비스를 종료하고자 할 경우, 회사는 종료하고자 하는 날로부터 3개월 이전에 홈페이지에 공지하고 계정에 등록된 회원의 SNS 및 전자메일을 통해 회원에게 공지한 후 서비스를 종료할 수
    있습니다.<br />
    <br />
    <Title>제15조 [KRW 적립 및 인출]</Title><br />
    ① 회원은 다음 각 호의 방법으로 전자지갑에 KRW를 적립할 수 있습니다.<br />
    1. 회원 본인실명확인 계좌인증을 완료한 경우에 한하여 해당 실명확인계좌를 통해 회사의 서비스계좌로 현금을 입금하는 경우<br />
    2. 회사가 계약을 통해 이용하는 제3의 전자금융사업자를 통해 회사의 서비스계좌로 현금을 입금하는 경우<br />
    <br />
    ② 회원이 회사의 서비스계좌에 현금을 입금함과 동시에 동 현금은 1원당 1KRW 비율로 KRW로 전환되어 적립됩니다.<br />
    <br />
    ③ 회원은 본인실명확인 계좌인증을 완료한 경우에 한하여 해당 실명확인계좌로 KRW를 현금으로 받을 수 있습니다. 다만 현금 출금과 관련한 출금한도 및 출금일은 운영정책에 따릅니다.<br />
    <br />
    ④ 회사는 정기점검, 시세조정・자금세탁・불공정거래・범죄행위 등의 방지를 위한 모니터링, 출금 한도와 같은 운영 정책에 따라 회원의 현금 입금 및 출금을 제한할 수 있습니다.<br />
    <br />
    ⑤ 트레져러에서 사용하는 KRW는 유효기간이 없으며, 회사는 KRW에 대한 이자지급 의무가 없습니다.<br />
    <br />
    ⑥ 회원이 보유한 KRW를 현금으로 인출하고자 할 경우, 회사는 별도의 수수료 책정할 수 있습니다.<br />
    <br />
    ⑦ 회원이 탈퇴할 경우, 트레져러 운영정책에 따라 보유한 KRW는 회사에 양도될 수 있습니다. 다만 휴면회원이 자동으로 탈퇴처리 될 경우 KRW는 회사에 전액 양도됩니다.<br />
    <br />
    <Title>제16조 [포인트 적립 및 발행]</Title><br />
    ① 회사는 트레져러에서 KRW와 동일하게 활용할 수 있는 “포인트”를 발행할 수 있으며, 1포인트는 1KRW와 같은 가치를 가집니다. 다만, 회원의 본인실명확인계좌로 출금은 할 수 없습니다.<br />
    <br />
    ② 회원은 회사가 제휴를 맺은 제휴사의 포인트를 트레져러의 포인트로 전환할 수 있습니다. 다만, 포인트 전환에 따른 수수료는 고객이 부담하여야 합니다.<br />
    <br />
    ③ 회사는 서비스 이용의 편의를 위해 여러 종류의 포인트를 발행할 수 있습니다.<br />
    <br />
    ④ 서비스에서 사용하는 포인트의 유효기간은 5년입니다.<br />
    <br />
    ⑤ 회원은 포인트를 KRW로 전환하거나 현금으로 환불을 받을 수 없습니다.<br />
    <br />
    ⑥ 회원이 탈퇴할 경우, 트레져러 운영정책에 따라 보유한 포인트는 회사에 양도될 수 있습니다. 다만, 포인트를 보유한 회원은 탈퇴로 인한 포인트 소멸의 불이익을 피하기 위해 탈퇴 전에 포인트를 소진하여야
    합니다.<br />
    <br />
    ⑦ 회원이 탈퇴할 경우, 트레져러 운영정책에 따라 보유한 포인트는 회사에 양도될 수 있습니다. 다만 휴면회원이 자동으로 탈퇴처리 될 경우 포인트는 회사에 전액 양도됩니다.<br />
    <br />
    <Title>제17조 [디지털자산 전송]</Title><br />
    ① 트레져러에서 회원이 보유한 자산을 다른 회원에게 또는 회원이 비회원에게 전송할 수 있습니다.<br />
    <br />
    ② 디지털자산을 전송하고자 하는 회원은 전송 대상자의 전화번호를 서비스에 등록하여 전송하며, 이를 수신한 회원 또는 비회원이 수령할 경우 전송이 완료됩니다. 다만, 디지털자산을 수령하기 위해서는 회원가입이
    완료되고, 전자지갑이 생성되어야 합니다.<br />
    <br />
    ③ 디지털자산이 전송되어 수신이 이루어지기 전까지 전송된 해당 자산은 매도 또는 전송이 제한됩니다. 다만, 일정기간 동안 해당 자산이 수신되지 않을 경우 전송이 취소되며 해당 자산은 디지털자산을 전송하고자 한
    회원에게 회수됩니다.<br />
    <br />
    ④ 디지털자산의 전송에 따른 수수료는 회원이 부담하여야 하며, 관련 수수료에 관하여는 운영정책을 따릅니다.<br />
    <br />
    ⑤ 디지털자산의 전송 시 회원의 오류 또는 착오로 인해 제3자에게 잘못 전송된 경우에는 회사가 이를 책임지지 않습니다.<br />
    <br />
    <Title>제18조 [상품권 실물교환]</Title><br />
    ① 실물 자산 교환<br />
    1. 상품권을 보유하고 있는 회원은 앱서비스 또는 오프라인(전화, 이메일)을 통하여 회사에 실물 금 교환을 청구할 수 있습니다.<br />
    2. 상품권에 대한 실물 자산으로 교환을 청구한 후 회사가 정한 기간 이후 지정한 곳에서 실물 자산으로 교환 받을 수 있습니다. 다만 다른 특별한 공지가 없는 한 ㈜트레져러 본점에서 교환 받을 수
    있습니다.<br />
    3. 실물 자산의 교환 시 발생하는 인출수수료, 세금(부가가치세 10%)은 회원이 부담하며, 회원이 회사에 납부 완료하여야 실물 자산으로 인출가능 합니다.<br />
    <br />
    ② 실물 자산 교환품목을 아래의 표와 같으며, 교환품목의 형태 및 제품의 브랜드 등은 서비스 운영정책 및 실물 시장의 여건에 따라 달라질 수 있습니다.<br />
    - e금: 실물 금(Gold, 순도 99.99%)<br />
    - e은: 실물 은(Silver, 순도 99.99%)<br />
    - e플래티넘: 실물 백금(PlaOnum, 순도 99.95%)<br />
    - e팔라듐: 실물 팔라듐(Palladium, 순도 99.95%)<br />
    <br />
    ③ 실물교환과 관련한 상세한 절차 및 수수료 등은 트레져러 운영서비스에 따릅니다.<br />
    <br />
    <Title>제19조 [상품권 시세]</Title><br />
    ① 회사는 다음 각호에 해당하는 시세 등의 정보를 제공할 수 있다.<br />
    1. 시세(현재가), 종가, 최고가, 최저가<br />
    2. 일거래량, 일거래금액<br />
    3. 누적거래량, 누적거래금액<br />
    4. 기타 거래에 참고가 되는 정보<br />
    <br />
    ② 회사는 상품권의 기준시세를 국제 원자재 시세, 원달러 환율 등과 국내 시장의 수급현황을 참조하여 회사가 정할 수 있습니다.<br />
    <br />
    <Title>제20조 [상품권 매수]</Title><br />
    ① 상품권을 매수하고자 하는 회원은 서비스를 통해 상품권 매수를 신청하면 회사는 회원에게 상품권을 판매합니다.<br />
    <br />
    ② 상품권 매수가 이루어지면, 상품권 매수를 신청한 회원의 전자지갑에서 상품권 매수 대금과 수수료를 더한 가격에 해당하는 KRW 또는 포인트를 공제함과 동시에 회원이 주문한 수량의 상품권을 회원의 전자지갑에
    전송하여 표시합니다. 아울러 회사의 전자지갑에 상품권 매도 대금만큼의 KRW가 전송·표시됩니다. 다만, 상품권을 판매한 회원의 경우 수수료를 차감한 KRW를 전송·표시할 수 있습니다.<br />
    <br />
    ④ 회원은 포인트를 사용하여 상품권을 매수할 수 있으며, 구매절차는 제2항과 동일합니다. 다만 상품권 매수 대금은 회원이 보유한 포인트를 우선 공제하며, 부족한 포인트는 KRW로 대체하여
    공제됩니다.<br />
    <br />
    ⑤ 회사가 상품권 거래의 당사자의 전자지갑에 상품권 및 KRW를 전송 표시함으로써 회원의 상품권 매수는 완료됩니다.<br />
    <br />
    <Title>제21조 [상품권 매도]</Title><br />
    ① 상품권을 매도하고자 하는 회원은 서비스를 통해 상품권 판매를 신청하면 회사는 상품권을 매수합니다.<br />
    <br />
    ② 상품권 매도를 신청한 회원의 전자지갑에는 매도 대금에서 수수료를 공제한 후 KRW를 전송·표시하며, 이와 동시에 회원이 주문한 수량의 상품권을 회원의 전자지갑에서 차감하여 표시합니다. 아울러 회사의
    전자지갑에 상품권 매수 대금만큼의 KRW 또는 포인트가 차감되며, 보유한 상품권 수량이 증가하여 표시됩니다.<br />
    <br />
    ③ 회사가 상품권 거래의 당사자의 전자지갑에 상품권 및 KRW를 전송 표시함으로써 회원의 상품권 매도는 완료됩니다.<br />
    <br />
    <Title>제22조 [환불]</Title><br />
    ① 회원은 상품권 구매일로부터 7일 이내에 구매액 전부를 환불받을 수 있습니다.<br />
    <br />
    ② 시간이 지나 다시 판매하기 곤란할 정도로 상품권의 가치가 현저히 감소한 경우에는 회원은 발행사의 의사에 반하여 제1항에 따른 환불을 할 수 없습니다.<br />
    <br />
    ③ 유효기간 경과 전 상품권 상의 물품 등이 제공이 불가능하거나 제공에 필요한 통상적인 기간보다 현저히 지체되는 경우 고객의 요구에 따라 발행자는 해당 상품권과 동일한 금전적 가치의 상품권으로 즉시 교환하거나
    구매액을 반환하여야 합니다.<br />
    <br />
    ④ 발행사는 본 조의 내용을 본 약관을 통하여 공지하는 것과는 별도로, 상품권에도 본 조의 내용의 요지를 표시합니다. 아울러 고객은 상품권 주문 시 별도로 명시되는 상품권 환불에 관한 내용을 확인하고 이에
    동의하여야 상품권을 구매할 수 있습니다. 다만, 고객의 환불과 관련한 제반 업무는 상품권 유통사가 대행할 수 있습니다.<br />
    <br />
    <Title>제23조 [매매거래 취소]</Title><br />
    ① 회사는 회원의 주문으로 체결된 매수, 매도 거래에 대해 다음 각호에 해당하는 경우 거래를 취소할 수 있으며, 해당 사실을 회원에게 통지하여야 합니다.<br />
    1. 회원 자격이 제한 또는 상실된 회원이 거래한 경우<br />
    2. 회원 정보를 도용하여 제3자가 거래한 경우<br />
    3. 범죄 또는 불법적 거래로 의심되거나 우려가 되는 거래한 경우<br />
    4. 시스템의 장애 발생으로 정상적인 매매거래를 할 수 없는 경우<br />
    5. 기타 구회사의 기술상 현저히 지장이 있다고 판단하는 경우<br />
    <br />
    ② 본 조 ①항에 따라 거래가 취소된 경우, 회사는 취소 후 3일 이내에 회원에게 구매 시점의 금액으로 환불하여야 한다. 단, 본 조 ①항에 따라 회원에게 발생한 이익을 금융계좌로 출금한 경우 이에 대한
    반환을 청구할 수 있습니다.<br />
    <br />
    <Title>제24조 [회원 개인정보보호]</Title><br />
    ① 회사는 관련 법령이 정하는 바에 따라 회원의 개인정보를 수집/이용하며, 관련 법령에서의 예외를 제외하고는 회원의 동의 없이 제3자에게 개인정보를 제공하지 않습니다.<br />
    <br />
    ② 회사는 회원의 개인정보를 보호하기 위하여 최선의 노력을 다하고 있으며, 회원의 개인정보 보호에 관한 사항은 관계 법령과 회사가 정하는 『개인정보처리방침』을 따릅니다.<br />
    <br />
    ③ 회사는 서비스 이용기간 중에 회원의 개인정보를 보유·이용하고 회원에서 탈퇴하거나 개인정보이용 목적을 달성한 경우 또는 일정기간 서비스를 이용하지 않아 개인정보의 유효기간이 도래하는 경우 수집된 개인정보를
    열람하거나 이용할 수 없도록 파기 처리하며, 개인정보의 유효기간 만료 1개월 이내 해당 사실을 전자적 방법으로(SMS, 이메일 등) 공지합니다.<br />
    <br />
    ④ 개인정보 유효기간은 1년이며, 개인정보의 유효기간은 회원이 서비스를 이용하지 않은 최근의 기록(로그인)으로부터 기산됩니다. 다만 관련 법령에서 다르게 정하는 경우에는 다음 각 호와 같이
    설정합니다.<br />
    1. 표시ㆍ광고에 관한 기록: 6개월<br />
    2. 계약 등에 관한 기록: 5년<br />
    3. 대금결제 및 재화등의 공급에 관한 기록: 5년<br />
    4. 소비자의 불만 또는 분쟁처리에 관한 기록: 3년<br />
    5. 그 외 다른 법령에서 다르게 정하는 경우: 해당 법령에서 정하는 기간<br />
    <br />
    <Title>제25조 [개인정보 보호 및 처리 위탁]</Title><br />
    ① 회사는 “정보통신망 이용촉진 및 정보보호 등에 관한 법률·시행령·시행규칙” 등 관계 법령이 정하는 바에 따라서 회원 등록정보를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다.<br />
    <br />
    ② 회원의 개인정보보호 및 사용에 대해서는 관계 법령 및 회사의 개인정보처리방침이 적용됩니다. 단, 회사의 공식 사이트 이외의 웹에서 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다. 또한
    회사는 회원의 귀책사유로 인해 유출된 정보에 대해서 일체의 책임을 지지 않습니다.<br />
    <br />
    ③ 회사는 필요한 경우 수집된 개인정보의 처리 및 관리 등의 업무의 일부 또는 전부를 회사가 선정한 제3자에게 위탁할 수 있습니다. 회원의 개인정보 처리 등의 위탁에 대해서는 회사의 개인정보처리방침이
    적용됩니다.<br />
    <br />
    <Title>제26조 [손해배상 범위 및 청구]</Title><br />
    ① 회원이 본 약관을 위반하거나 불법행위로 인하여 발생하는 모든 문제는 회원에게 책임이 있습니다. 이로 인하여 발생하는 회사 또는 제3자의 손해에 대하여 회원은 손해액 전액을 배상하여야 합니다.<br />
    <br />
    ② 회사는 회원의 서비스 이용에 따른 손익에 대해 책임지지 않습니다.<br />
    <br />
    ③ 본 조 제1항으로 회사가 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해 회원은 그로
    인하여 회사에 발생한 모든 손해를 배상하여야 합니다.<br />
    <br />
    <Title>제27조 [면책사항]</Title><br />
    ① 회사 또는 회원은 본 약관을 위반하여 상대방에게 손해를 입힌 경우에는 그 손해를 배상할 책임이 있습니다. 다만, 행위자의 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.<br />
    <br />
    ② 회사는 다음 각 호의 경우로 서비스를 제공하지 못하여 회원에게 발생한 손해에 대해서는 책임을 면합니다.<br />
    1. 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우<br />
    2. 정부 기관의 사실상 또는 법률상 행정처분 및 명령 등 이에 준하는 법적 조치로 인한 경우<br />
    3. 서비스의 효율적인 제공을 위한 시스템 개선, 장비 증설 등 계획된 서비스 중지 일정을 사전에 공지한 경우<br />
    4. 회원의 귀책사유로 서비스 이용에 장애가 있는 경우<br />
    5. 회사에게 회선, 통신망, 전용선을 제공하고 있는 이동통신사 또는 부가통신사업자 측의 장애·귀책사유로 인한 경우<br />
    6. 기타 회사의 고의 과실이 없는 사유로 인한 경우<br />
    <br />
    ③ 회사는 회원이 서비스를 통해 얻은 정보 등으로 인해 발생한 손해와 서비스를 이용하거나 이용할 것으로부터 발생하거나 기대하는 손익 등에 대하여 책임을 면합니다.<br />
    <br />
    ④ 회사는 서비스 이용의 장애로 인하여 발생한 회원의 부가적, 간접적, 영업적인 손해에 대해서는 책임을 면합니다.<br />
    <br />
    ⑤ 회사는 회원이 게시 또는 전송한 자료의 내용에 대해서는 책임을 면합니다.<br />
    <br />
    ⑥ 회사는 무료로 제공하는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 면합니다.<br />
    <br />
    <Title>제28조 [양도금지]</Title><br />
    회원은 서비스의 이용권한, 기타 본 약관상의 지위를 타인에게 양도, 증여, 판매, 담보제공 등의 처분행위를 할 수 없습니다.<br />
    <br />
    <Title>제29조 [준거법 및 관할법원]</Title><br />
    ① 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우 분쟁의 해결을 위해 성실히 협의합니다.<br />
    ② 본 조 제1항의 협의에서도 분쟁이 해결되지 않을 경우 분쟁 당사자는 민사소송법상의 관할법원에 소를 제기할 수 있습니다.<br />
    ③ 서비스 이용 및 본 약관과 관련하여 발생하는 분쟁에 대해서는 약관의 규제에 관한 법률」, 「전자상거래 등에서의 소비자 보호에 관한 법률」, 「전자금융거래법」, 「전자금융거래 기본약관」, 「전기통신기본법」,
    「전기통신사업법」, 「여신전문금융업법」등 관계법령 및 거래관행에 따릅니다.<br />
    부칙<br />
    (시행일) 이 이용약관은 2023년 3월 20일부터 적용됩니다.<br />
  </div>;
};

export { Terms };