import React from 'react';
import { Content } from '../content';
import { Title } from '../title';
import { Card } from '../card';

const TreasurerThirdPartyPrivacyPolicy = () => {
  return <div className="max-w-[1140px] m-auto w-full px-6 py-10 lg:px-2 xl:px-0">
    <Title className="sm:text-[30px] text-[20px] text-center">개인정보 제3자 정보 제공 동의</Title>

    <Title className="pt-6 pb-2"> [필수] 개인정보 수집·이용 동의</Title>
    <Content>
      <Card className="flex flex-col justify-between text-black">
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1">
            <li>수집 ∙ 이용 목적</li>
          </div>
          <div className="py-2 flex-1">트레져러 플랫폼 사용 시 본인 확인</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1">
            <li>개인정보항목</li>
          </div>
          <div className="py-2 flex-1">CI(암호화), 성명, 생년월일, 성별, 내/외국인 정보</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1">
            <li>보유기간</li>
          </div>
          <div className="py-2 flex-1">트레져러 회원 탈퇴시 까지</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1">
            <li>제공받는 기관</li>
          </div>
          <div className="py-2 flex-1">주식회사 트레져러</div>
        </div>
      </Card>
      <div className="mt-[20px]">귀하는 개인정보 수집∙이용에 동의를 거부할 수 있습니다. 다만, 이에 동의하지 않는 경우에는 회사가 제공하는 트레져러 플랫폼 서비스를 이용할 수
        없습니다.
      </div>
    </Content>
  </div>;
};

export { TreasurerThirdPartyPrivacyPolicy };