import React from 'react';
import { Title } from '../title';

const GroupPurchaseTerm = () => {
  return <div className="max-w-[1140px] m-auto w-full px-6 py-10 lg:px-2 xl:px-0">
    <Title className="sm:text-[30px] text-[20px] text-center">트레져러 공동구매 약관</Title>

    <Title>제1조(목적)</Title><br />
    이 약관은 ㈜트레져러(이하 “회사”라 합니다)가 제공하는 트레져러 서비스상에서 상품의 등록 및 공동소유를 실시함에 있어 그 절차와 제반 기준 및 관련 회원들의 권리 및 의무에 대하여 규정함을 목적으로 합니다.<br />
    <br />
    <Title>제2조(정의)</Title><br />
    1.“공동소유”라 함은, 등록된 상품이 최초 매수되어 둘 이상의 회원이 소유하게 되는 것을 의미합니다.<br />
    <br />
    2. “공동소유총액”이라 함은, 등록된 상품이 공동소유될 시 100%를 소유함에 필요한 총 금액을 의미합니다.<br />
    <br />
    3. “위탁해지”라 함은 회사가 위탁을 맡은 공동소유 상품에 대한 위탁 계약이 해지됨을 말합니다.<br />
    <br />
    4. “보관관리자”라 함은, 회사로부터 상품의 보관을 위탁 받은 자를 의미합니다.<br />
    <br />
    5. “예치금”이라 함은, 이 약관 및 회사의 정책에 따라서 회원의 결제대금에 비례해서 회사가 회원에게 부여하는 포인트를 의미합니다.<br />
    <br />
    6. “영업일”이라 함은, 토요일, 일요일 및 공휴일을 제외한 날을 의미합니다.<br />
    <br />
    7. “이용계약”이라 함은, 회사 또는 그 계열회사의 회원이 되기 위하여 회사 또는 그 계열회사와 체결하는 계약을 의미합니다.<br />
    <br />
    8. “상품”이라 함은, 회원 또는 회사가 플랫폼에 등록한 물품을 의미합니다.<br />
    <br />
    9. “조각”이라 함은, 상품에 대하여 둘 이상으로 균등하게 분할된 공유 지분을 의미하고, “조각 소유자”라 함은, 조각을 소유하는 회원을 의미합니다.<br />
    <br />
    10. “최소판매조각”이라 함은, 회사가 정하는 상품이 공동소유되기 위해 판매 되어야 하는 최소 판매 조각을 의미합니다. 이는 전체 조각 중 판매 조각이 50% 미만일 경우 해당합니다.<br />
    <br />
    11. “플랫폼”이라 함은, 회사가 온라인으로 제공하는 상품의 등록 및 공동소유의 장을 의미합니다.<br />
    <br />
    12. “회원”이라 함은, 회사에 개인 정보를 제공하여 회원 등록을 한 자를 의미합니다.<br />
    <br />
    <Title>제3조(예치금 관리 및 구매대금결제)</Title><br />
    1.회사의 플랫폼에 등록된 자산을 구매하기 위해서는 예치금을 선충전해야합니다. 예치금 충전은 회사 또는 및 결제 업체에서 제한하는 방식 중 선택하여 충전할 수 있습니다. 충전된 금액은 ‘마이페이지’에서 확인
    가능합니다.<br />
    <br />
    2. 회원의 예치금은 플랫폼에 회원의 계좌를 등록 후 해당 계좌로 회사 영업일의 영업시간 (10:00 - 19:00) 내에 출금이 가능합니다<br />
    <br />
    <Title>제4조(상품의 등록 및 공동소유 방식)</Title><br />
    1. 회사는 등록된 상품을 회사가 지정한 장소에 보관하며, 공동 소유하는 회원으로 하여금 예약 후 상품 확인 및 관람 하도록 할 수 있습니다. 회원은 회사 및 결제 업체가 정하는 정책, 방법에 따라 플랫폼내
    예치금을 입금함으로써 매수를 희망하는 분할소유권을 결제할 수 있습니다. 각 상품의 구매 한도는 통상적으로 전체 조각의 30%의 수량과 10,000 조각 중 더 높은 수량으로 정하며 이는 추후에 회사 및 결제
    업체의 정책, 정부의 방침 등에 따라 조정될 수 있습니다.<br />
    <br />
    2. 회원은 분할소유권 조각 판매 기간 종료 후 7일 이내, 최소구매조각에 미달하여 해당 상품에 대한 판매가 완료되지 않는 경우 구매가 자동 취소됩니다. 매매가 취소된 회원은 해당 분할소유권의 매수를 위해
    지급된 결제 대금 원금을 예치금 형태로 반환 받습니다.<br />
    <br />
    3. 조각 구매한 상품의 취소는 공동 소유 조각 판매가 종료되기 전까지 가능합니다. 구매가 취소된 금액은 자동으로 예치금으로 충전됩니다. 공동 구매가 종료되었거나 조각 판매 기간이 종료된 경우에는 취소가
    불가합니다.<br />
    <br />
    <Title>제5조(공동소유 후 보관 및 처분)</Title><br />
    1. 상품은 회사 또는 회사로부터 해당 업무를 위탁 받은 관리자가 보관 및 관리합니다. 회원은 분할소유권 매수와 동시에 회사에 대하여 상품의 보관, 관리, 운영, 판매를 위탁합니다. 회사는 상품이 등록되었을
    때의 상태를 최대한 유지할 수 있도록 상품에 필요한 평균적인 보관 환경을 조성하는 등 선량한 관리자의 주의의무를 다하는 것으로 그 보관, 관리에 따른 의무를 다합니다. 회사 또는 보관관리자는 상품을, 회사
    또는 보관관리자의 보관장소에서 상시 전시, 외부 임대 및 이벤트에 활용하는 방식 등으로, 운영할 수 있습니다. 상품의 운영으로 수익이 발생한 경우 그 수익의 일부는 상품의 보관, 관리, 운영, 판매 위탁에
    대한 수수료 명목으로 회사에 귀속됩니다. 조각소유자는 분할소유권 매수와 동시에 회사에 해당 상품의 판매를 위탁합니다. 회사는 조각소유자를 위하여 선관주의의를 가지고 판매회사를 선임하여 처분 시기와 처분 가격
    등을 결정하여 처분 절차를 진행하되, 상품의 처분은 조각소유자의 이익을 보장하는 것은 아닙니다. 회사가 필요하다고 판단하는 경우, 조각소유자와 회사는 이 약관과 별개의 처분위탁계약서를 체결할 수
    있습니다.<br />
    <br />
    2. 상품의 처분에 따라 발생한 원금과 수익은 총 판매 대금에서 판매 시 발생한 비용 및 상품 관리시 발생한 비용 및 위탁 수수료(매각 대금의 2%)를 제외한 후 조각소유자에게 그 소유한 조각에 비례하여
    귀속됩니다.<br />
    <br />
    3. 공동 소유로 판매된 상품은 판매시 별도로 기재한 경우를 제외하고 판매 완료 후 12개월이 지난 시점부터 조각 소유 구매자들의 원금과 이익을 위해 판매를 개시합니다. 다만, 조각 소유자에게 매각 투표를
    진행하여 기 투표 인원의 과반수가 매각을 희망하는 경우 판매 시점이 변경될 수 있습니다.<br />
    <br />
    ■ 매각희망가 투표<br />
    · 투표 제안 : 해당 상품 조각을 1% 이상 보유한 유저<br />
    · 투표 진행 : 투표 승인으로부터 24시간(트레져러에서 투표 진행 여부 결정)<br />
    · 투표 결과 : 투표 조각수가 전체 조각수의 50% 이상이고 투표 조각 중 찬성이 70% 이상이면 가결<br />
    · 매각희망가 변경 : 48시간 이내 트레져러에서 해당 물품 판매가 변경<br />
    <br />
    ■ 매각 투표<br />
    · 투표 시작 : 해당 상품의 구매희망자가 나타나면 조각 소유자를 대상으로 매각투표 진행<br />
    · 투표 진행 : 24시간 동안 진행<br />
    · 투표 결과 : 투표율이 과반수가 넘고 매각 반대 의견보다 찬성이 더 많을 경우에만 매각 진행, 투표율이 과반수가 넘지 않을 경우 매각이 부결된다.<br />
    · 매각 가결 시 : 매각 진행 및 수익금 지급<br />
    <br />
    <Title>제6조(회사 위탁해지)</Title><br />
    1. 회사는, 다음과 같은 사건 발생시 공동 소유 상품의 관리, 보관, 판매 등에 대한 위탁은 해지됩니다.<br />
    - 가. 상품의 최소 공동소유되지 않는 경우<br />
    - 나. 분할소유권을 가진 회원들의 전원 동의가 있는 경우<br />
    - 다. 등록상품의 상태에 심각한 문제가 발생하여 물리적인 보관이 어렵다고 회사가 판단하는 경우<br />
    - 라. 회사가 파산하는 경우<br />
    <br />
    2. 위탁해지 사건 발생 시 해당 상품을 매각하여 그 매각대금을 분할소유권 공유조각율에 따라 분할 소유권자에게 배분됩니다. 다만, 분할소유권을 가진 회원들 전원 동의가 있는 경우 매각 절차에 갈음하여 해당
    상품을 지정 회원에게 반환 또는 지정업체를 통해 반환할 수 있습니다.<br />
    <br />
    <Title>제7조(이용계약 해지)</Title><br />
    1. 상품을 공동소유 하거나, 상품을 공동소유 하려는 회원은, 그 소유권을 처분하거나 공동소유권의 매수를 취소하지 아니하면 이용계약을 임의로 해지할 수 없습니다. 그럼에도 불구하고 회원이 이용계약을 해지하는
    경우, 회사는 회원이 공동소유를 포기한 것으로 간주할 수 있습니다.<br />
    <br />
    2. 회원이 이용계약을 임의로 해지하는 경우, 회사 및 결제업체가 정하는 정책, 방법 등에 따라 회원이 소유하는 현금을 회원에게 지급합니다. 회원이 현금을 수령하지 않는 경우 공탁 또는 회사가 판단하는
    방법으로 관리하되, 관리수수료가 차감됩니다.<br />
    제8조 (수수료)<br />
    1. 판매위탁수수료: 등록위탁수수료는 작품 조각 판매 금액의 10%(부가가치세별도)이며, 회사가 등록위탁자에게 공동소유대금을 지급할 때 등록위탁수수료를 제한 후 조각 판매 금액을 지급합니다.<br />
    <br />
    2. 플랫폼이용수수료: 수수료 정책[https://treasurer.co.kr/privacy#2] 참조<br />
    <br />
    <Title>제10조(준거법 및 재판관할)</Title><br />
    1. 판매위탁수수료: 등록위탁수수료는 작품 조각 판매 금액의 10%(부가가치세별도)이며, 회사가 등록위탁자에게 공동소유대금을 지급할 때 등록위탁수수료를 제한 후 조각 판매 금액을 지급합니다.<br />
    <br />
    2. 플랫폼이용수수료: 수수료 정책[https://treasurer.co.kr/privacy#2] 참조<br />
    <br />
    <Title>부칙</Title><br />
    (시행일) 이 이용약관은 2024년 5월 9일부터 적용됩니다.<br />
  </div>;
};

export { GroupPurchaseTerm };