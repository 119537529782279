import React from 'react';

const Section3 = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div ref={ref} className="xs max-w-[1140px] mx-auto px-5 lg:px-2 xl:px-0 py-[65px] sm:py-20">
      <div className="lg:text-[40px] md:text-[32px] text-[26px] font-bold text-[#151825] text-center md:text-start font-p-bolder">MyB</div>
      <div className="mt-6 sm:mt-10 md:mt-11">
        <h3 className="lg:text-[21px] text-[18px] font-PretendardBoldFont font-bold  text-[#010206] w-[298px] sm:w-auto break-keep">
          <span className="text-[#2268F5] mr-[2px]"> MyB</span> 는 나의 다양한 취향을 인증하고 보상받는 지갑 기반의 리워드 앱입니다.
        </h3>
        <h5 className="text-[#666666] font-medium mt-3 break-keep text-[0.75rem] sm:text-[1rem] max-w-[320px] sm:max-w-full">
          MyB 서비스를 통해 담고 이를 통해 수익을 창출 할 수 있습니다. MyB는 2024년 1분기 출시를 목표로 개발 중에 있습니다.
        </h5>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-3 sm:gap-8 mt-7">
        <div className="col-span-1 space-y-2 md:space-y-3 sm:text-center">
          <div className="bg-radial-gradient rounded-[20px] h-[130px] pt-0 sm:pt-5 sm:max-h-[200px] pl-6 sm:pl-0 sm:h-full flex justify-between w-full sm:flex-col items-center overflow-hidden sm:mb-5 pr-3 md:pr-0">
            <div className="flex flex-col">
              <div className="text-[26px] font-p-bold">
                <span className="text-[#2268F5]">B</span>
                <span className="text-[#8893C1]">e</span>
              </div>
              <div className="text-[16px] block sm:hidden text-[#8893C1] font-normal break-keep">나의 존재</div>
            </div>
            <div>
              <img src="/images/be.png" alt="1" />
            </div>
          </div>
          <div className="hidden sm:block text-[18px] text-[#8893C1]">나의 존재</div>
        </div>
        <div className="col-span-1 space-y-2 md:space-y-3 sm:text-center">
          <div className="bg-radial-gradient rounded-[20px] h-[130px] pt-0 sm:pt-5 sm:max-h-[200px] pl-6 sm:pl-0 sm:h-full flex justify-between w-full sm:flex-col items-center overflow-hidden sm:mb-5 pr-3 md:pr-0">
            <div className="flex flex-col">
              <div className="text-[26px] font-p-bold">
                <span className="text-[#2268F5]">B</span>
                <span className="text-[#8893C1]">enefit</span>
              </div>
              <div className="text-[16px] block sm:hidden text-[#8893C1] font-normal break-keep">나의 혜택</div>
            </div>
            <div>
              <img src="/images/benefit.png" alt="1" />
            </div>
          </div>
          <div className="hidden sm:block text-[18px] text-[#8893C1]">나의 혜택</div>
        </div>
        <div className="col-span-1 space-y-2 md:space-y-3 sm:text-center">
          <div className="bg-radial-gradient rounded-[20px] h-[130px] pt-0 sm:pt-5 sm:max-h-[200px] pl-6 sm:pl-0 sm:h-full flex justify-between w-full sm:flex-col items-center overflow-hidden sm:mb-5 pr-3 md:pr-0">
            <div className="flex flex-col">
              <div className="text-[26px] font-p-bold">
                <span className="text-[#2268F5]">B</span>
                <span className="text-[#8893C1]">adge</span>
              </div>
              <div className="text-[16px] block sm:hidden text-[#8893C1] font-normal break-keep">나의 뱃지</div>
            </div>
            <div>
              <img src="/images/badge.png" alt="1" />
            </div>
          </div>
          <div className="hidden sm:block text-[18px] text-[#8893C1]">나의 뱃지</div>
        </div>
        <div className="col-span-1 space-y-2 md:space-y-3 sm:text-center">
          <div className="bg-radial-gradient rounded-[20px] h-[130px] pt-0 sm:pt-5 sm:max-h-[200px] pl-6 sm:pl-0 sm:h-full flex justify-between w-full sm:flex-col items-center overflow-hidden sm:mb-5 pr-3 md:pr-0">
            <div className="flex flex-col">
              <div className="text-[26px] font-p-bold">
                <span className="text-[#2268F5]">B</span>
                <span className="text-[#8893C1]">elongings</span>
              </div>
              <div className="text-[16px] block sm:hidden text-[#8893C1] font-normal break-keep">나의 자산과 소속</div>
            </div>
            <div>
              <img src="/images/belongings.png" alt="1" />
            </div>
          </div>
          <div className="hidden sm:block text-[18px] text-[#8893C1]">나의 자산과 소속</div>
        </div>
        <div className="col-span-1 space-y-2 md:space-y-3 sm:text-center">
          <div className="bg-radial-gradient rounded-[20px] h-[130px] pt-0 sm:pt-5 sm:max-h-[200px] pl-6 sm:pl-0 sm:h-full flex justify-between w-full sm:flex-col items-center overflow-hidden sm:mb-5 pr-3 md:pr-0">
            <div className="flex flex-col">
              <div className="text-[26px] font-p-bold">
                <span className="text-[#8893C1]">data </span>
                <span className="text-[#2268F5]">B</span>
                <span className="text-[#8893C1]">ase</span>
              </div>
              <div className="text-[16px] block sm:hidden text-[#8893C1] font-normal break-keep">나의 데이터</div>
            </div>
            <div>
              <img src="/images/database.png" alt="1" />
            </div>
          </div>
          <div className="hidden sm:block text-[18px] text-[#8893C1]">나의 데이터</div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-1 mt-[2rem] md:mt-[3.5rem]">
        <div className=" col-span-1 mb-6 lg:mb-0">
          <div className="flex flex-col md:flex-row space-y-4 items-start md:space-x-5">
            <div className="bg-[#EAEFFA] h-[200px] md:max-w-[200px] rounded-[20px] w-full flex justify-center items-center py-4">
              <img src="/images/web-image.png" alt="6" />
            </div>
            <div className="flex flex-col flex-wrap items-start pt-0 md:pt-9">
              <div className="text-[16px] xl:text-[18px] text-black font-p-bold break-keep sm:max-w-[320px] mb-2">
                <span className="text-[#2268F5]"> MyB </span>는 Web 3.0 기술을 활용합니다.
              </div>
              <div className="undefined grid lg:block xl:grid font-PretendardMediumFont font-medium text-[12px] sm:text-[13px] text-[#666666] break-keep  sm:max-w-[320px]">
                <span>
                  {' '}
                  뱃지는 SBT(SoulBound Token)으로 발행되어, 블록체인에 기록됩니다. 내가 가진 SBT 뱃지는 고유하고 불변하며 전송할 수 없습니다.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className=" col-span-1 mb-6 lg:mb-0">
          <div className="flex flex-col md:flex-row space-y-4 items-start md:space-x-5">
            <div className="bg-[#EAEFFA] h-[200px] md:max-w-[200px] rounded-[20px] w-full flex justify-center items-center py-4">
              <img src="/images/data-base.png" alt="6" />
            </div>
            <div className="flex flex-col flex-wrap items-start pt-0 md:pt-9">
              <div className="undefined text-[16px] xl:text-[18px] text-black font-p-bold break-keep sm:max-w-[320px] mb-2">
                나의 데이터는 나만의 개인형 데이터베이스 (Personal Database)에 저장됩니다
              </div>
              <div className="grid lg:block xl:grid font-PretendardMediumFont font-medium text-[12px] sm:text-[13px] text-[#666666] break-keep  sm:max-w-[320px]">
                <span>나의 다양한 데이터를 내가 직접 소유하고 관리하며 이를 통해 수익을 창출할 수 있습니다.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidde  sm:flex item-center bg-[url('/public/images/wemix-bg.webp')] bg-right md:bg-bottom bg-cover bg-no-repeat rounded-[1rem] h-[153px] mt-[5rem] w-full">
        <img src="/images/img_1sw_xpla_banner.png" alt="7" className="rounded-[20px]" />
      </div>
    </div>
  );
});

export { Section3 };
