import React from 'react';
import { Title } from '../title';

const MarketingAgree = () => {
  return <div className="max-w-[1140px] m-auto w-full px-6 py-10 lg:px-2 xl:px-0">
    <Title className="sm:text-[30px] text-[20px] text-center">트레져러 마케팅 수신 동의</Title>

    <Title>마케팅 정보 활용</Title><br />
    마케팅 정보 수신 여부 및 마케팅을 위한 개인정보 수집이용을 거부하실 수 있으며, 거부 시에도 트레져러서비스를 이용하실 수 있으나, 동의를 거부한 경우 각종 소식 및 이벤트 참여에 제한이 있을 수
    있습니다.<br />
    <br />
    트레져러(이하, ‘서비스‘라 합니다.)“의 서비스 제공 및 이용과 관련하여 트레져러㈜(이하, ‘회사’라 합니다)가 취득한 개인정보는 “통신비밀보호법“, “전기통신사업법” 및 “정보통신망 이용촉진 및 정보보호
    등에 관한 법률” 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보 보호 규정을 준수합니다.<br />
    <br />
    1. 서비스에서는 고객이 수집 및 이용에 동의한 개인정보를 활용하여, 전자적 전송 매체(SMS/MMS/App Push 등 다양한 전송 매체)를 통하여, 회사 및 제3자의 상품 또는 서비스에 대한 개인 맞춤형
    광고 정보를 전송할 수 있습니다.<br />
    2. 고객이 본 수신 동의를 철회하고자 할 경우 고객센터를 통하여 수신 동의 철회 요청을 할 수 있습니다.<br />
    <br />
    [이용항목]<br />
    - 휴대폰정보 (통신사,휴대폰번호), 동의 일시<br />
    [이용목적]<br />
    - 마케팅 광고에 활용<br />
    <br />
    1) 서비스 개발 및 개선, 서비스 관련 문의 등에 대한 개인 맞춤 상담 정보 제공<br />
    2) 신규 서비스나 이벤트, 제3자의 상품 또는 서비스 관련 개인 맞춤형 광고 정보 전송<br />
    <br />
    [보유 및 이용기간]<br />
    - 별도 동의 철회 시 까지<br />
    - 단, 관련 법령의 규정에 따라 보존할 필요가 있을 경우, 해당 기간까지 보존 함<br />
    <br />
    <Title>제28조 [양도금지]</Title><br />
    회원은 서비스의 이용권한, 기타 본 약관상의 지위를 타인에게 양도, 증여, 판매, 담보제공 등의 처분행위를 할 수 없습니다.<br />
    <br />
    <Title>제29조 [준거법 및 관할법원]</Title><br />
    ① 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우 분쟁의 해결을 위해 성실히 협의합니다.<br />
    ② 본 조 제1항의 협의에서도 분쟁이 해결되지 않을 경우 분쟁 당사자는 민사소송법상의 관할법원에 소를 제기할 수 있습니다.<br />
    ③ 서비스 이용 및 본 약관과 관련하여 발생하는 분쟁에 대해서는 약관의 규제에 관한 법률」, 「전자상거래 등에서의 소비자 보호에 관한 법률」, 「전자금융거래법」, 「전자금융거래 기본약관」, 「전기통신기본법」,
    「전기통신사업법」, 「여신전문금융업법」등 관계법령 및 거래관행에 따릅니다.<br />
    <br />
    <Title>부칙</Title><br />
    (시행일) 이 이용약관은 2023년 3월 20일부터 적용됩니다.<br />
  </div>;
};

export { MarketingAgree };